.center {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > .center {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    color: rgba($color: #000000, $alpha: 0.3);
  }
}
