@import "../components/layout.scss";
@import "../components/spin.scss";

* {
  box-sizing: border-box;
}
a,
button {
  outline: none;
}

.btn-circle {
  display: inline-block;
  border: none;
  text-decoration: none;
  color: white;
  text-align: center;
  background: linear-gradient(0deg, #f8994e, #f9aa6a);
  border-radius: 50%;
  box-shadow: 0px 4px 5px 0px rgba(255, 113, 0, 0.25);

  line-height: 27px;
  width: 27px;
  height: 27px;
  padding: 0;

  &.big {
    font-size: 17px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  img {
    vertical-align: middle;
  }
}

.line {
  min-width: 1pt;
  min-height: 1pt;
  background-color: rgba($color: #ffffff, $alpha: 0.5);

  &.v {
    width: 1pt;
  }
}

.page-prepare {
  background: linear-gradient(46deg, #ff827c 1%, #ffde9d 99%);
  height: 100vh;
  padding: 1em;

  header {
    text-align: center;
    margin-bottom: 12px;

    .button {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      background: linear-gradient(0deg, #f8994e, #f9aa6a);
      border: none;
      border-radius: 12px;
      box-shadow: 0px 4px 5px 0px rgba(255, 113, 0, 0.25);
      color: white;
      font-size: 12px;
      padding: 0 1em;
    }
  }

  main {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 8px 15px 0px rgba(181, 54, 45, 0.3);
  }
  footer {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1em 1em 0 0;
    height: 60px;
    padding: 10px;
    display: flex;

    .selected-pose-list {
      flex: 1;
      display: flex;

      & > div {
        position: relative;
        margin-right: 10px;
      }

      i {
        position: absolute;
        top: 0;
        right: 0;
        width: 13px;
        height: 13px;
        background: #ff3f25;
        border-radius: 50%;
        color: white;
        line-height: 13px;
        display: inline-block;
        text-align: center;
      }
    }

    .btn-help {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      display: inline-block;
      border: none;
      text-decoration: none;
      background: linear-gradient(180deg, #ff9743, #ff7910);
      border-radius: 20px;
      font-size: 17px;
      color: white;
      text-align: center;

      img {
        width: 24px;
        height: 24px;
        vertical-align: -15%;
        margin-right: 5px;
      }
    }
  }

  .action-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: -1px 0px 1px 0px rgba($color: #000000, $alpha: 0.2);

    &.small {
      width: 40px;
      height: 40px;
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    padding: 1em 0;
    margin: 0 1em;
    border-bottom: 1px solid #efeff2;

    &:last-child {
      border: none;
    }

    label {
      flex: 1;
    }
  }
}

.page-game {
  height: 100vh;

  main {
    position: relative;
    height: 100vh;
    background-color: #000000;
    color: white;
    font-size: 12px;

    .spin {
      width: 30px;
      height: 30px;
    }

    canvas {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  header {
    height: 78px;
    opacity: 0.8;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    padding: 18px;
    color: white;

    h2 {
      font-size: 16px;
      font-size: 16px;
      margin: 0;
    }

    .counter {
      flex: 1;
      text-align: center;

      .n {
        font-size: 39px;
        font-family: Impact;
        color: #f89c52;
      }
      .m {
        font-size: 20px;
        font-family: Impact;
      }
    }

    .button {
      width: 23px;
      height: 23px;
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 92px;
    opacity: 0.8;
    background: #000000;
    color: #ffffff;
    padding: 6px 18px;

    h2 {
      margin: 0;
      padding: 6px 0;
      font-size: 21px;
      font-family: Impact;
    }

    p {
      margin: 0;
      font-size: 12px;
    }

    .button {
      float: right;
      width: 23px;
      height: 23px;
    }
  }

  .score-dialog {
    width: 266px;
  }
}

.user-head {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &.big {
    width: 44px;
    height: 44px;
  }
}

.score {
  color: #f89c52;
  font-size: 12px;
  big {
    font-size: 20px;
    font-family: Impact;
    &.big {
      font-size: 25px;
    }
  }
}

.page-ranking {
  // background: linear-gradient(46deg, #ff827c 1%, #ffde9d 99%);
  background-position: -17px 4px, 0;
  background-repeat: no-repeat;
  background-size: 74px 76px, auto;
  min-height: 100vh;
  padding: 1em 1em 76px;

  h1 {
    font-size: 20px;
    color: #81390e;
    margin: 6px 56px 9px;
  }

  .button {
    height: 24px;
    line-height: 24px;
    background: linear-gradient(0deg, #f8994e, #f9aa6a);
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 5px 0px rgba(255, 113, 0, 0.25);
    color: white;
    font-size: 12px;
    float: right;
    margin-top: 7px;
    margin-right: 4px;
    padding: 0 1em;
  }

  main {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 8px 15px 0px rgba(181, 54, 45, 0.3);

    .ranking-item {
      display: flex;
      align-items: center;
      padding: 1em;
      margin: 0 1em;
      border-bottom: 1px solid #efeff2;
      font-size: 13px;

      i {
        display: block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
        color: #8e9098;
        font-style: normal;

        &.first {
          color: #9e6d12;
          background-color: #ffd83a;
        }

        &.second {
          color: #858585;
          background-color: #d4d4d4;
        }

        &.third {
          color: #834e1a;
          background-color: #eec080;
        }
      }

      .user-head {
        margin-right: 16px;
      }

      &:last-child {
        border: none;
      }

      label {
        flex: 1;
      }
    }
  }
  footer {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1em 1em 0 0;
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;

    .user-ranking {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;

      .user-head {
        margin-right: 12px;
      }

      .small {
        font-size: 12px;
        color: #72798d;
      }

      .action-list {
        flex: 1;
      }
    }

    button {
      display: flex;
      align-items: center;

      border: none;
      text-decoration: none;
      background: linear-gradient(180deg, #ff9743, #ff7910);
      border-radius: 20px;
      font-size: 17px;
      color: white;
      text-align: center;

      .action-icon {
        vertical-align: center;
        display: inline-block;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.page-profile {
  min-height: 100vh;
  background-color: #f2f2f2;
  background-size: 100% 243px;
  background-repeat: no-repeat;

  header {
    text-align: center;
    padding-top: 23px;
    color: white;

    .user-head {
      width: 60px;
      height: 60px;
      border: 2px solid white;
    }
    p {
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 16px;
    }

    .btn-grids {
      display: flex;
      border-top: 1pt solid rgba($color: #ffffff, $alpha: 0.5);

      & > .grid {
        flex: 1;
        padding: 16px;
        font-size: 12px;
        display: flex;
        justify-items: center;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          width: 50px;
          height: 40px;
          object-fit: none;
        }
        div {
          text-align: left;
          line-height: 1.2em;
          span {
            font-size: 19px;
          }
        }
      }
      .line {
        margin: 17px 0;
        width: 1pt;
      }
    }
  }

  .cells {
    margin: 0 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 3px 5px 0px rgba(164, 158, 180, 0.3);

    .cell {
      display: flex;
      align-items: center;
      font-size: 15px;
      height: 54px;
      margin: 0 17px;
      border-bottom: 1px solid #efeff2;

      &:last-child {
        border: none;
      }

      label {
        flex: 1;
        margin-left: 8px;
      }

      img {
        min-width: 20px;
        object-fit: none;
      }
    }
  }

  .user-list {
    margin: 29px 20px 0;
    text-align: center;

    .user-head {
      width: 50px;
      height: 50px;
      margin: 0 6px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.page-fen {
  background: #f2f2f2;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;

  header {
    background: #ff6d59;
    color: white;
    font-size: 13px;
    padding: 18px;

    .big {
      font-size: 30px;
    }

    .hint {
      font-size: 12px;
      color: #ffeac1;
    }
  }

  main {
    flex: 1;

    .hint {
      font-size: 13px;
      color: #8b8b95;
      padding: 1em;
    }

    .cells {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .cell {
        width: 90px;
        height: 100px;
        border: 1px solid #ff6d59;
        border-radius: 8px;
        margin: 6px;
        padding: 20px 10px;
        font-size: 18px;
        color: #ff6d59;
        &.selected {
          background-color: white;
        }
        &.disabled {
          color: #8b8b95;
          border-color: #8b8b95;
        }
        .small {
          font-size: 14px;
        }
      }
    }
  }

  footer {
    padding: 1em;
    .button {
      height: 40px;
      line-height: 40px;
      padding: 0 2em;
      display: inline-block;
      border: none;
      text-decoration: none;
      background: #ffed88;
      border-radius: 20px;
      font-size: 17px;
      color: #621106;
      text-align: center;
    }
  }
}

.page-fen-records {
  background: #f2f2f2;
  min-height: 100vh;

  header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1em 1em 0;

    .tab {
      flex: 1;
      text-align: center;
      color: #8e8e96;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.active {
        color: #1e1e20;
        font-size: 16px;

        &::after {
          display: inline-block;
          content: "";
          width: 14px;
          height: 3px;
          opacity: 1;
          background: #ff3f25;
          border-radius: 2px;
          margin-top: 4px;
        }
      }
    }
  }

  main {
    padding-bottom: 10px;

    .record {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      box-shadow: 0px 2px 0px 0px rgba(30, 30, 32, 0.08);
      margin: 10px;
      padding: 12px 18px 11px;

      .score {
        float: right;
        font-size: 14px;
        color: #493f33;
        padding-top: 4px;
      }
    }
  }
}

.page-exercise-records {
  background: #f2f2f2;
  min-height: 100vh;

  main {
    padding: 10px 0;

    .record {
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      box-shadow: 0px 2px 0px 0px rgba(30, 30, 32, 0.08);
      margin: 10px;
      padding: 12px 18px 11px;
      display: flex;
      align-items: center;

      .action-icon {
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        box-shadow: -1px 0px 1px 0px rgba($color: #000000, $alpha: 0.2);
      }

      .score {
        float: right;
        color: #b3997e;
        font-size: 14px;
      }
    }

    .hint {
      font-size: 13px;
      color: #8b8b95;
      padding: 1em;
      text-align: center;
    }
  }
}

.dialog-score {
  text-align: center;

  background-color: white;
  border-radius: 15px;

  p {
    margin: 0;
  }

  .header {
    margin-top: -140px;
    margin-left: -3.5px;
    margin-right: -5.5px;
  }

  .golden {
    font-size: 15px;
    color: #a28c48;
  }

  .red {
    font-size: 13px;
    color: #e55f51;
    big {
      font-size: 48px;
    }
  }

  .gray {
    font-size: 12px;
    color: #4c5054;
  }

  .button {
    margin: 13px 0 18px;
    width: 96px;
    height: 28px;
    line-height: 28px;
    background: linear-gradient(0deg, #ff827c, #ffde9d);
    border: none;
    border-radius: 14px;
    font-size: 13px;
    color: #ffffff;
    text-shadow: 0px 0px 0px 0px rgba(148, 30, 21, 0.3);
  }
}

.dialog-bgm {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #292929;
  border-radius: 1em;
  padding: 1em;
  color: white;

  .toggle {
    float: right;
    width: 35px;
    height: 16px;
    vertical-align: middle;
  }

  .content {
    background: #343434;
    border-radius: 0.5em;

    .cell {
      padding: 8px 25px;
      line-height: 25px;

      &.selected {
        color: #f89d55;
      }
    }
  }
}
