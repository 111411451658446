.title-divider {
  margin: 18px 0;
  display: flex;
  align-items: center;

  .line {
    flex: 1;
    height: 1pt;
    background-color: #d9d7df;
  }

  .title {
    padding: 0 1.2em;
    color: #5e5e65;
    font-size: 13px;
  }
}
