.weui-picker-weekdays {
  margin-bottom: 1em;
  label {
    display: inline-block;
    margin-bottom: 7px;

    input {
      visibility: hidden;
      width: 4px;
    }

    b {
      display: inline-block;
      padding: 0 1em;
      line-height: 26px;
      height: 26px;
      border-radius: 13px;
      font-weight: normal;
      font-size: 14px;
      color: #40444a;
      background: #e5e7ed;
    }

    input:checked + b {
      background: #f89c52;
      color: white;
    }
  }
}
