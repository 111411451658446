.weui-open-hint {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998;

  img {
    width: 52px;
    height: 67px;
    display: block;
    position: absolute;
    right: 30px;
    top: 20px;
  }

  p {
    padding-top: 107px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
  }
}
